import React, { Fragment, useState, useContext } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core";
import { MiniButton, SnackbarBanner, TypographyComponent } from "gx-npm-ui";
import { DeleteTrashcanIcon } from "gx-npm-icons";
import { deleteBannerStyles as styles } from "./bulk-delete-initiative-banner.styles";
import ActionDialog from "../../../ui/action-dialog/action-dialog.component";
import { isValidResponse, postAsyncRequest } from "gx-npm-lib";
import { DashBoardAppContext } from "../../../app.context";
import { Initiative } from "../../../app.types";
const useStyles = makeStyles(() => styles);

const BulkDeleteInitiativeBanner = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [permanentDeleteDialog, setPermanentDeleteDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const { userInitiatives, setUserInitiatives, setSnackbarMessage, bulkDeleteIds, setShowSnackBar } =
    useContext(DashBoardAppContext);
  const bulkMessage =
    bulkDeleteIds.length > 1
      ? t("evaluations have been permanently deleted")
      : t("evaluation has been permanently deleted");

  const handleDeleteClick = () => {
    setPermanentDeleteDialog(true);
  };
  const handleBulkDelete = async () => {
    setIsLoading(true);
    const url = `/api/v2/initiatives/delete-many`;
    const response = await postAsyncRequest(url, { initIds: bulkDeleteIds });
    if (isValidResponse(response)) {
      setIsLoading(false);
      setPermanentDeleteDialog(false);
      setSnackbarMessage(`${bulkDeleteIds.length} ${bulkMessage}`);
      setShowSnackBar(true);
      const updatedList = (userInitiatives as Initiative[])?.filter((item) => !bulkDeleteIds.includes(item.id));
      setUserInitiatives(updatedList);
    } else {
      setIsLoading(false);
      setIsError(true);
    }
  };
  return (
    <Fragment>
      <div className={classNames(classes.root)}>
        <TypographyComponent rootClassName={classes.text} styling={"p3"} color={"coal"} boldness={"regular"}>
          {t("Evaluations cleared from your trash will be permanently deleted")}
        </TypographyComponent>
        <MiniButton variant={"destructive"} onClick={handleDeleteClick}>
          <DeleteTrashcanIcon />
          <div aria-label={"trashed evaluation count"}>
            <TypographyComponent styling={"p3"} color={"poisonCherry"} boldness={"medium"}>
              {t("Delete evaluations")}
              {` (${bulkDeleteIds.length})`}
            </TypographyComponent>
          </div>
        </MiniButton>
      </div>
      <ActionDialog
        confirmTextValue={"delete"}
        displayConfirmTextbox={true}
        handleClose={() => setPermanentDeleteDialog(false)}
        isOpen={permanentDeleteDialog}
        handleConfirm={handleBulkDelete}
        textBodyStart={t("Are you sure you want to permanently delete")}
        displayName={` ${bulkDeleteIds.length} `}
        textBodyEnd={t("evaluations? Type “Delete” to confirm you want to permanently remove these evaluations.")}
        textButtonCancel={t("CANCEL")}
        textButtonConfirm={t("PERMANENTLY DELETE")}
        textConfirmLabel={t('Type "Delete" to confirm')}
        textTitle={t("Permanently delete evaluations")}
        isLoading={isLoading}
      />
      <SnackbarBanner isOpen={isError} isDefaultErrorMessage={true} type="ERROR" setIsOpen={setIsError} />
    </Fragment>
  );
};

export default BulkDeleteInitiativeBanner;
