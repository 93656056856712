import { makeStyles } from "@material-ui/core";
import classNames from "classnames";
import { InitState } from "gx-npm-lib";
import { TypographyComponent } from "gx-npm-ui";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { DashBoardAppContext } from "../../app.context";
import { getStateLabel, isArchiveOrDeleteState } from "../../lib";
import { getInitiativeStateLabels } from "../../lib/get-initiatives-state";
import ToggleLink from "../../ui/toggle-link/index";
import { HeaderStyle as styles } from "./dashboard-header.styles";

const useStyles = makeStyles(() => styles);
const DashboardHeader = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { currentState, setCurrentState } = useContext(DashBoardAppContext);
  const displayStateLabel = isArchiveOrDeleteState(currentState);
  const stateLabel = getStateLabel(currentState);

  const handleStateChange = (selectedState: InitState) => {
    setCurrentState(selectedState);
  };

  return (
    <div className={classNames(classes.container)}>
      <TypographyComponent color="carbon" styling="h3" boldness={"medium"}>
        {t("My evaluations")}
      </TypographyComponent>

      <TypographyComponent color="iron" rootClassName={classes.stateLabel} styling="p1">
        {displayStateLabel && `( ${stateLabel} )`}
      </TypographyComponent>
      {currentState && (
        <ToggleLink
          defaultActiveItem={InitState.ACTIVE}
          items={getInitiativeStateLabels()}
          onChange={handleStateChange}
        />
      )}
    </div>
  );
};

export default DashboardHeader;
