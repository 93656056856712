import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import * as singleSpa from "single-spa";
import { makeStyles } from "@material-ui/core";
import { Button, TypographyComponent } from "gx-npm-ui";
import { evalNotStartedViewStyles as styles } from "./eval-not-started-view.styles";

const propTypes = {
  hasWarningBanner: PropTypes.bool,
  imgHeight: PropTypes.number,
  imgSrc: PropTypes.string,
  isDisplayingLink: PropTypes.bool,
  title: PropTypes.string,
  subtitle: PropTypes.string,
};
const useStyles = makeStyles(() => styles);
const EvalNotStartedView = ({
  hasWarningBanner = false,
  imgHeight = 0,
  imgSrc = "",
  isDisplayingLink = false,
  title = "",
  subtitle = "",
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <div className={classNames("evel-not-started", classes.container, hasWarningBanner && classes.hasWarningBanner)}>
      <div>
        <img alt={t("No records")} height={imgHeight} src={imgSrc} />
      </div>
      <TypographyComponent rootClassName={classes.title} boldness={"medium"} styling={"h4"} color={"iron"}>
        {title}
      </TypographyComponent>
      <TypographyComponent rootClassName={classes.subTitle} color={"iron"} styling={"p3"} boldness={"regular"}>
        {t(subtitle)}
      </TypographyComponent>

      {isDisplayingLink && (
        <Button onClick={() => singleSpa.navigateToUrl("/s/markets")} rootClassName="btn-tertiary">
          <div>
            <TypographyComponent styling={"p3"} color={"defaultCta"} boldness={"semi"}>
              {t("BROWSE MARKETS")}
            </TypographyComponent>
          </div>
        </Button>
      )}
    </div>
  );
};

EvalNotStartedView.propTypes = propTypes;
export default EvalNotStartedView;
