import { colorPalette, weightPalette } from "gx-npm-common-styles";

const borderValues = `1px solid ${colorPalette.neutrals.silver.hex}`;
const weightAmtBold = weightPalette.bold.amount;
const weightVarBold = weightPalette.bold.variation;

const evalNotStartedViewStyles = {
  container: {
    borderBottom: borderValues,
    borderTop: borderValues,
    fontVariationSettings: weightVarBold,
    fontWeight: weightAmtBold,
    paddingBottom: 60,
    paddingTop: 60,
    textAlign: "center" as const,
    "&$hasWarningBanner": {
      borderTop: "none",
    },
  },
  title: {
    paddingBottom: 8,
    paddingTop: 48,
  },
  subTitle: {
    paddingBottom: 28,
  },
  hasWarningBanner: {},
};

export { evalNotStartedViewStyles };
